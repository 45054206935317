
DIV.admin-tasks-section {
    border: #ff0000 0px dashed;
    background-image: linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url('/src/assets/images/stock/temp/stethescope.jpg   ');
    overflow: hidden;
    background-size: 100% 150%;
}

DIV.echart_sankey {
    width: 100%; 
    min-height:800px;
}

section.masthead-hc {
    background-color: #ffffff;
    background-image: linear-gradient(to bottom, rgba(188, 188, 188, 0.6) 0%,rgba(197, 197, 197, 0.6) 100%), url(../assets/images/genai/medical-office-ext.jpg);
    background-size: cover;
    margin-top: -6px !important;
    margin-bottom: 12px;
}

DIV.banner-hc {
    width: 100% !important;
    max-height: 300px;
    overflow: hidden;
}

DIV.banner-hc > IMG {
    width: 100% !important;
    margin-top: -80px !important;
}

DIV.banner-hc > DIV.banner_content_hc {
    margin: 20px 0 0 20px;
    padding:10px;
    position: absolute;
    height: fit-content;
    vertical-align: middle;
    color: #000000;
    background-color: rgba(200, 200, 200, 0.6);
    border-radius: 10px;
}


@media (max-width: 767.97px) {
    DIV.banner_content_hc > H3 {
        font-size: 12pt;
        padding-top: 40px;
    }
    
    DIV.banner_content_hc > H1 {
        font-size: 18pt;
    }
    
    DIV.banner_content_hc > P {
        font-size: 10px;
        max-width: 250px;
    }    

    DIV.banner-hc > IMG {
        margin-top: -10px !important;
    }
    
}


@media (min-width: 767.98px) {
    DIV.banner_content_hc > H3 {
        font-size: 12pt;
        padding-top: 28px;
    }
    
    DIV.banner_content_hc > H1 {
        font-size: 20pt;
    }
    
    DIV.banner_content_hc > P {
        font-size: 14px;
        max-width: 320px;
    }    
    DIV.banner-hc > IMG {
        margin-top: -10px !important;
    }

}

@media (min-width: 991.98px) {
    DIV.banner_content_hc > H3 {
        font-size: 14pt;
        padding-top: 40px;
    }
    
    DIV.banner_content_hc > H1 {
        font-size: 26pt;
    }
    
    DIV.banner_content_hc > P {
        font-size: 16px;
        max-width: 420px;
    }    

    DIV.banner_hc > IMG {
        width: 100% !important;
        margin-top: -60px;
    }

}


@media (min-width: 1199.98px) {
    DIV.banner_content_ad > H3 {
        font-size: 14pt;
        padding-top: 40px;
    }

    DIV.banner_content_ad > H1 {
        font-size: 26pt;
    }
    
    DIV.banner_content_ad > P {
        font-size: 16px;
        max-width: 420px;
    }    

    DIV.banner_ad > IMG {
        width: 100% !important;
        margin-top: -80px !important;
    }
}


