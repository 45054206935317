section.masthead-ad {
    background-color: #ffffff;
    background-image: linear-gradient(to bottom, rgba(188, 188, 188, 0.6) 0%,rgba(197, 197, 197, 0.6) 100%), url(../assets/images/genai/adultday_home_ext.jpg);
    background-size: cover;
    margin-top: -6px !important;
    margin-bottom: 12px;

    /* background: linear-gradient(
        to left,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 10%,
        rgba(255, 255, 255, 1) 90%,
        rgba(255, 255, 255, 0) 100%
      ); */
}

DIV.home-hero-ad {
    border: #ff0000 0px dashed;
    background-image: url(../assets/images/genai/adultday_home_ext.jpg) ;
    background-size: cover;
    overflow: hidden;
    background-repeat: repeat-x;
    margin-top: 0px !important;
}

DIV.banner-ad {
    width: 100% !important;
    max-height: 300px;
    overflow: hidden;
}

DIV.banner-ad > IMG {
    width: 100% !important;
}

DIV.banner-ad > DIV.banner_content_ad {
    margin: 20px 0 0 20px;
    padding:10px;
    position: absolute;
    height: fit-content;
    vertical-align: middle;
    color: #000000;
    background-color: rgba(200, 200, 200, 0.6);
    border-radius: 10px;
}


@media (max-width: 767.97px) {
    DIV.banner_content_ad > H3 {
        font-size: 12pt;
        padding-top: 40px;
    }
    
    DIV.banner_content_ad > H1 {
        font-size: 18pt;
    }
    
    DIV.banner_content_ad > P {
        font-size: 10px;
        max-width: 250px;
    }    
}


@media (min-width: 767.98px) {
    DIV.banner_content_ad > H3 {
        font-size: 12pt;
        padding-top: 28px;
    }
    
    DIV.banner_content_ad > H1 {
        font-size: 20pt;
    }
    
    DIV.banner_content_ad > P {
        font-size: 14px;
        max-width: 320px;
    }    

}

@media (min-width: 991.98px) {
    DIV.banner_content_ad > H3 {
        font-size: 14pt;
        padding-top: 40px;
    }
    
    DIV.banner_content_ad > H1 {
        font-size: 26pt;
    }
    
    DIV.banner_content_ad > P {
        font-size: 16px;
        max-width: 420px;
    }    

    DIV.banner_ad > IMG {
        width: 100% !important;
        margin-top: -60px;
    }

}


@media (min-width: 1199.98px) {
    DIV.banner_content_ad > H3 {
        font-size: 14pt;
        padding-top: 40px;
    }

    DIV.banner_content_ad > H1 {
        font-size: 26pt;
    }
    
    DIV.banner_content_ad > P {
        font-size: 16px;
        max-width: 420px;
    }    

    DIV.banner_ad > IMG {
        width: 100% !important;
        margin-top: -80px;
    }
}



.card .card-text {
    font-size: 14pt;
    font-weight: bold;
    text-align: center;
}

.card.feature-card{
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
      transition: 1.3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
    padding: 14px 80px 18px 36px;
    cursor: pointer;
    overflow: hidden !important;
    transform: scale(1.02) linear 0s;
    box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
  }

  /* .card.feature-card:hover {
     transform: scale(1.02) linear 0s;
     box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
    } */
  
  .card-hover-text{
      display:none;
  }
  .card-heading{
    display:none;
    font-weight: bold;
}
.card .card-text{
      display:none;
  }
  .card .card-heading{
    display:block;
}
.card .card-hover-text{
      display:block;
}

.card-img-top{
    position:absolute;
    display: block;
    width: 100%;
    margin-left: -35px;
    opacity: 0.3;
    filter: alpha(opacity=20);
  }

  .card-body{
    z-index: 9999;
  }