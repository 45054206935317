section.masthead {
    background-color: #ffffff;
    background-image: linear-gradient(to bottom, rgba(188, 188, 188, 0.6) 0%,rgba(197, 197, 197, 0.6) 100%), url(../assets/images/genai/healthcare_home_ext.png);
    background-size: cover;
    margin-top: -6px !important;
    margin-bottom: 12px;

    /* background: linear-gradient(
        to left,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 10%,
        rgba(255, 255, 255, 1) 90%,
        rgba(255, 255, 255, 0) 100%
      ); */
}

DIV.home-hero {
    border: #ff0000 0px dashed;
    background-image: url(../assets/images/genai/healthcare_home_ext.png) ;
    background-size: cover;
    overflow: hidden;
    background-repeat: repeat-x;
    margin-top: 0px !important;
}

DIV.banner {
    width: 100% !important;
    max-height: 160px;
    overflow: hidden;
}

DIV.banner > IMG {
    width: 100% !important;
}

DIV.banner > DIV.banner_content {
    position: absolute;
    left: 50%;
    height: fit-content;
    vertical-align: middle;
    color: #ffffff;
}

.top-stripe-blue {
    border-top: #080893 8px solid !important;
}

.top-stripe-orange {
    border-top: #de4a00 8px solid !important;
}

.bg-med-gray {
    background-color: #dddddd !important;
}



.white {
    color: white;
}

@media (max-width: 767.97px) {
    DIV.banner_content > H3 {
        font-size: 12pt;
        padding-top: 40px;
    }
    
    DIV.banner_content > H1 {
        font-size: 18pt;
    }
    
    DIV.banner_content > P {
        font-size: 10px;
        max-width: 250px;
    }    
}


@media (min-width: 767.98px) {
    DIV.banner_content > H3 {
        font-size: 12pt;
        padding-top: 28px;
    }
    
    DIV.banner_content > H1 {
        font-size: 20pt;
    }
    
    DIV.banner_content > P {
        font-size: 14px;
        max-width: 320px;
    }    

}

@media (min-width: 991.98px) {
    DIV.banner_content > H3 {
        font-size: 14pt;
        padding-top: 40px;
    }
    
    DIV.banner_content > H1 {
        font-size: 26pt;
    }
    
    DIV.banner_content > P {
        font-size: 16px;
        max-width: 420px;
    }    

    DIV.banner > IMG {
        width: 100% !important;
        margin-top: -60px;
    }

}


@media (min-width: 1199.98px) {
    DIV.banner_content > H1 {
        font-size: 24pt;
        margin-top:10px;
    }
    
    DIV.banner_content > P {
        font-size: 14px;
        max-width: 420px;
    }    

    DIV.banner > IMG {
        width: 100% !important;
        margin-top: -140px;
    }
}

/* stats formatting */

.circle-tile {
    margin-bottom: 15px;
    text-align: center;
    border-radius: 3px !important;
}
.circle-tile-heading {
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-radius: 100%;
    color: #FFFFFF;
    height: 80px;
    margin: 0 auto -40px;
    position: relative;
    transition: all 0.3s ease-in-out 0s;
    width: 80px;
}
.circle-tile-heading .fa {
    line-height: 80px;
}
.circle-tile-content {
    padding-top: 50px;
}
.circle-tile-number {
    font-size: 26px;
    font-weight: 700;
    line-height: 1;
    padding: 5px 0 15px;
}
.circle-tile-description {
    text-transform: uppercase;
}
.circle-tile-footer {
    background-color: rgba(0, 0, 0, 0.1);
    color: rgba(255, 255, 255, 0.5);
    display: block;
    padding: 5px;
    transition: all 0.3s ease-in-out 0s;
}
.circle-tile-footer:hover {
    background-color: rgba(0, 0, 0, 0.2);
    color: rgba(255, 255, 255, 0.5);
    text-decoration: none;
}
.circle-tile-heading.dark-blue:hover {
    background-color: #2E4154;
}
.circle-tile-heading.green:hover {
    background-color: #138F77;
}
.circle-tile-heading.orange:hover {
    background-color: #DA8C10;
}
.circle-tile-heading.blue:hover {
    background-color: #2473A6;
}
.circle-tile-heading.red:hover {
    background-color: #CF4435;
}
.circle-tile-heading.purple:hover {
    background-color: #7F3D9B;
}
.tile-img {
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.9);
}

.dark-blue {
    background-color: #34495E;
}
.green {
    background-color: #16A085;
}
.blue {
    background-color: #2980B9;
}
.orange {
    background-color: #F39C12;
}
.red {
    background-color: #E74C3C;
}
.purple {
    background-color: #8E44AD;
}
.dark-gray {
    background-color: #7F8C8D;
}
.gray {
    background-color: #95A5A6;
}
.light-gray {
    background-color: #BDC3C7;
}
.yellow {
    background-color: #F1C40F;
}
.text-dark-blue {
    color: #34495E;
}
.text-green {
    color: #16A085;
}
.text-blue {
    color: #2980B9;
}
.text-orange {
    color: #F39C12;
}
.text-red {
    color: #E74C3C;
}
.text-purple {
    color: #8E44AD;
}
.text-faded {
    color: rgba(255, 255, 255, 0.7);
}