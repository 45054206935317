html {
  position: relative;
  min-height: 100%;
}

.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

   
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background: linear-gradient(90deg, #22326e 0%, #005eb8 100%) !important;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  BODY {
    margin-top: 60px;
    overflow-x: hidden;
    margin-bottom: 220px; /* footer height */
  }

  a.navbar-brand {
    white-space: nowrap;
  }

  a.navbar-brand > img {
    border: #ff0000 0px dashed;
    height: 80px;
    overflow: hidden;
    margin: -30px -30px -25px -30px;
  }

  nav.header-bg {
    background: linear-gradient(270deg, #22328e 0%, #55aef8 100%) !important;
  }

  footer {
    position: absolute;
  	bottom: 0;
  	left: 0;
  	right: 0;
  }

  footer.footer-bg {
    background: linear-gradient(270deg, #72a2ee 0%, #55aef8 100%) !important;
  }
  span.brand {
    color:#4289e5;
    font-style: italic;
  }

  a.footer-brand {
    white-space: nowrap;
    color: #000000;
    text-decoration: none;
    font-size: 14pt;    
  }

  a.footer-brand > img {
    border: #ff0000 0px dashed;
    height: 80px;
    overflow: hidden;
    margin: -30px -30px -25px -30px;
  }



DIV.homecare-hero {
  border: #ff0000 0px dashed;
  background: rgba(255, 255, 255, 0.5) url(../assets/images/genai/med_staff_ext.jpg);
  background-size: cover;
  overflow: hidden;
  background-repeat: repeat-x;
  margin-top: -4px !important;  
}


  DIV.footer-subheading {
    font-size:12pt;
    font-weight:bold;
  }

  li > a.footer-link-light{
    color: #cccccc !important;
  }

  li > a.footer-link-light:hover{
    color: #eeeeee !important;
  }

  li > a.footer-link-dark{
    color: #444444 !important;
  }

  li > a.footer-link-dark:hover{
    color: #000000 !important;
  }

DIV.copyright {
  font-size: 9pt;
  margin-bottom:6px;
  padding-bottom: 6px;
}

IMG.brand-bullet-icon {
  max-width: 80px;
  margin: -12px -15px 0 -15px;
}

section.masthead-subpage {
  background-color: #ffffff;
  background-image: linear-gradient(to bottom, rgba(188, 188, 188, 0.6) 0%,rgba(197, 197, 197, 0.6) 100%), url(../assets/images/genai/med_staff_ext.jpg);
  background-size: cover;
  margin-top: -6px !important;
  margin-bottom: 12px;
}

DIV.banner-subpage {
  width: 100% !important;
  max-height: 120px;
  overflow: hidden;
}

DIV.banner-subpage > IMG {
  width: 100% !important;
  margin-top: -80px !important;
}

DIV.banner-subpage > DIV.banner_content_subpage {
  margin: 20px 0 0 40%;
  float: right;
  padding:10px;
  position: absolute;
  height: fit-content;
  vertical-align: middle;
  color: #000000;
  background-color: rgba(200, 200, 200, 0.6);
  border-radius: 10px;
}

DIV.roundy-20 {
  border-radius: 20px;
}

.btn-group-xs > .btn, .btn-xs {
  padding: .25rem .4rem;
  font-size: .875rem;
  line-height: .5;
  border-radius: .2rem;
}

IMG.appstore_icons {
  max-width: 140px;
}